import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  claim?: string
  description?: string
  image?: ImageProps
  languageCode: string
  shopURL?: string
  title?: string
}

export const Oil = memo(function Oil({
  claim,
  description,
  image,
  languageCode,
  shopURL,
  title,
}: Props) {
  if (!image) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <Items>
          <Item row={false} dial={5}>
            <Top row dial={5}>
              <LazyLoadComponent>
                <StyledImage {...image} />
              </LazyLoadComponent>
            </Top>
            <FadeInUp>
              <Bottom row={false} dial={5}>
                {claim ? <Claim>{claim}</Claim> : null}
                {title ? <Title>{title}</Title> : null}
                {description ? (
                  <Description
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                ) : null}
                {shopURL ? (
                  <StyledButton
                    URL={shopURL}
                    target="_blank"
                    label={useVocabularyData('buy-it', languageCode)}
                  />
                ) : null}
              </Bottom>
            </FadeInUp>
          </Item>
        </Items>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 10rem 8.938vw;

  @media (max-width: 1199px) {
    margin: 5.625rem 1.5625rem;
  }
`

const Wrapper = styled.div`
  max-width: 82.125rem;
  margin: 0 auto;
`

const Items = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Top = styled(FlexBox)`
  width: 100%;
  height: 100%;
  padding-bottom: 3.125rem;

  @media (max-width: 992px) {
    padding-bottom: 2.5rem;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 100%;
  max-width: 5.375rem;
  max-height: 23rem;
  aspect-ratio: 86/368;

  @media (max-width: 992px) {
    max-height: 17.125rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Bottom = styled(FlexBox)`
  text-align: center;
`

const Claim = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 1.0625rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-transform: uppercase;
`

const Title = styled.div`
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 1.875rem;
  line-height: 2rem;
  text-transform: uppercase;
`

const Description = styled.div`
  display: block;
  width: 100%;
  padding: 0.625rem 0 2.5rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.02em;
  line-height: 1.8125rem;

  @media (max-width: 1199px) {
    padding-bottom: 1.875rem;
  }
`

const StyledButton = styled(Button)``

const Item = styled(FlexBox)`
  max-width: 26.125rem;
`
